import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "@/components/section-title";
import { FeatureTabData } from "@/data";

const WhyChooseUs = () => {
  const { sectionContent, posts } = FeatureTabData;
  const post = posts[0];

  return (
    <section className="commonSection chooseUs">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={sectionContent} />
          </Col>
        </Row>
        <div className="tab-content">
          <div className="tab-pane fade show active animated fadeIn">
            <Row>
              <Col lg={7}>
                <div className="wh_choose">
                  <p>{post.content}</p>
                  {/*
                  <ul>
                    {post.lists.map(({ item }, index) => (
                      <li key={index}>
                        <i className="fa fa-check-square"></i> {item}
                      </li>
                    ))}
                  </ul>
                  */}
                </div>
              </Col>
              <Col lg={5}>
                <div className="chose_img">
                  <img src={post.image} alt={``} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default WhyChooseUs;